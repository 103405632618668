import { Component } from "react";
import styles from "./index.module.less";
import axios from "axios";
import { BASE_API, YJJ_BASE_API } from '../../config';
import { validateMobile } from '../../utils/validate';
import Toast from "../../components/Toast";
import { storage } from '../../utils/storage';

export default class CheckinForm extends Component {
  state = {
    activityId: '', // 活动id
    photoPath: '', // 照片地址
    photoPathShow: '', // 照片地址 - 当前页展示
    name: '', // 姓名
    phoneNumber: '', // 手机号
    currentPosition: '', // 当前位置
    intentionPosition: '', // 意向位置
    remark: '', // 备注
    verifyCode: '', // 验证码

    // 发送验证码按钮文字
    verifyText: '发送验证码',
  }
  chooseImg = () => {
    console.log(this.file)
    let file = this.file.files[0]
    console.log(file)
    let formData = new FormData()
    formData.append('bucketName', 'fzzht-fashion-college-student')
    formData.append('files', file)
    const hideLoading = Toast.loading('上传中...')
    axios({
      url: BASE_API + '/minio-oss/fileUpload',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData
    }).then(res => {
      hideLoading()
      console.log(res.data)
      res = res.data
      if (res.erroyCode === 200) {
        this.setState({
          photoPath: res.data[0],
          photoPathShow: URL.createObjectURL(file),
        })
      } else {
        this.file.value = ''
        Toast.error('上传失败')
      }
    }).catch(err => {
      hideLoading()
      this.file.value = ''
      Toast.error('上传失败')
      console.error(err)
    })
  }

  saveName = e => this.setState({ name: e.target.value })

  savePhoneNumber = e => this.setState({ phoneNumber: e.target.value })

  saveVerifyCode = e => this.setState({ verifyCode: e.target.value })

  saveCurrentPosition = e => this.setState({ currentPosition: e.target.value })

  saveIntentionPosition = e => this.setState({ intentionPosition: e.target.value })

  saveRemark = e => this.setState({ remark: e.target.value })

  timer = null
  verifyBtnActive = true
  verifyTime = 60

  sentCode = () => {
    console.log('3232')
    console.log(this.verifyBtnActive)
    let phoneNumber = this.state.phoneNumber.trim()
    if (phoneNumber === '') {
      Toast.error("请输入手机号")
      return
    }
    if (!validateMobile(phoneNumber)) {
      Toast.error("手机号格式不正确")
      return
    }
    if (this.verifyBtnActive) {
      this.verifyBtnActive = false
      const hideLoading = Toast.loading('发送中...')
      axios({
        url: YJJ_BASE_API + '/yijiajiaUserInfo/RequestPhoneCode',
        method: 'post',
        data: {
          phone: phoneNumber
        }
      }).then(res => {
        hideLoading()
        console.log(res.data)
        res = res.data
        if (res.errorCode === 200) {
          Toast.success("验证码发送成功")
        } else {
          Toast.error("验证码发送失败")
        }
      }).catch(err => {
        hideLoading()
        Toast.error("验证码发送失败")
        console.error(err)
      })

      this.timer = setInterval(() => {
        if (this.verifyTime >= 0) {
          this.setState({
            verifyText: this.verifyTime + 's'
          })
          this.verifyTime--;
        } else {
          this.verifyBtnActive = true;
          this.setState({
            verifyText: '发送验证码'
          })
          this.verifyTime = 60;
          clearInterval(this.timer);
        }
      }, 1000)
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    console.log('提交')
    let {
      activityId,
      photoPath,
      name,
      phoneNumber,
      currentPosition,
      intentionPosition,
      remark,
      verifyCode,
    } = this.state
    if (!photoPath) {
      Toast.error("请上传个人照片")
      return
    }
    name = name.trim()
    if (!name) {
      Toast.error("请输入姓名")
      return
    }
    phoneNumber = phoneNumber.trim()
    if (phoneNumber === '') {
      Toast.error("请输入手机号")
      return
    }
    if (!validateMobile(phoneNumber)) {
      Toast.error("手机号格式不正确")
      return
    }
    verifyCode = verifyCode.trim()
    if (!verifyCode) {
      Toast.error("请输入验证码")
      return
    }
    currentPosition = currentPosition.trim()
    if (!currentPosition) {
      Toast.error("请输入当前职位")
      return
    }
    intentionPosition = intentionPosition.trim()
    if (!intentionPosition) {
      Toast.error("请输入意向职位")
      return
    }
    const hideLoading = Toast.loading('提交中...')
    axios({
      url: BASE_API + '/course-checkin/save',
      method: 'post',
      data: {
        activityId,
        photoPath,
        name,
        phoneNumber,
        currentPosition,
        intentionPosition,
        remark,
        verifyCode,
      }
    }).then(res => {
      hideLoading()
      console.log(res.data)
      res = res.data
      if (res.erroyCode === 200) {
        localStorage.setItem(storage.activityId + '_' + activityId, 1)
        Toast.success("签到成功")
        setTimeout(() => {
          this.props.history.push('/checkin-result');
        }, 1500)
      } else {
        Toast.info(res.errorMsg || '网络异常')
      }
    }).catch(err => {
      hideLoading()
      Toast.error("网络异常")
      console.error(err)
    })
  }

  componentDidMount(){
    console.log('Count---componentDidMount');
    const {activityId} = this.props.match.params
    console.log(activityId)
    this.setState({activityId})
    let localActivity = localStorage.getItem(storage.activityId + '_' + activityId)
    if (localActivity) {
      this.props.history.push('/checkin-result')
    }
  }


  render() {
    let { photoPathShow, verifyText } = this.state
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h3 className={styles.title}>您好！请签到</h3>
          <p className={styles.info}>填写以下信息，即可完成签到</p>
        </div>
        <form className={styles.form} onSubmit={this.handleSubmit}>
          <div className={styles.block}>
            <span className={styles.label}>个人照片</span>
            {photoPathShow ? <img className={styles.uploadImg} src={photoPathShow} alt="图" /> : ''}
            <input className={styles.inputFile} type="file" accept="image/*" ref={c => this.file = c} onChange={this.chooseImg} />
            <span className={styles.uploadBtn}></span>
          </div>
          <div className={styles.block}>
            <span className={styles.label}>姓名</span>
            <input className={styles.input} type="text" placeholder="请填写您的姓名" onChange={this.saveName} />
          </div>
          <div className={styles.block}>
            <span className={styles.label}>手机号</span>
            <input className={styles.input} type="tel" placeholder="请填写11位手机号" maxLength="11" onChange={this.savePhoneNumber} />
          </div>
          <div className={styles.block}>
            <span className={styles.label}>验证码</span>
            <input className={styles.input} type="tel" placeholder="请输入验证码" onChange={this.saveVerifyCode} />
            <span className={styles.sentBtn} onClick={this.sentCode}>{verifyText}</span>
          </div>
          <div className={styles.block}>
            <span className={styles.label}>当前职位</span>
            <input className={styles.input} type="text" placeholder="请填写您目前从事的职位" maxLength="20" onChange={this.saveCurrentPosition} />
          </div>
          <div className={styles.block}>
            <span className={styles.label}>意向职位</span>
            <input className={styles.input} type="text" placeholder="请填写您未来想从事的职位" maxLength="20" onChange={this.saveIntentionPosition} />
          </div>
          <div className={styles.block2}>
            <span className={`${styles.label} ${styles.label2}`}>备注(选填)</span>
            <textarea className={styles.textarea} cols="30" rows="10" placeholder="请输入备注信息，如自我介绍" onChange={this.saveRemark}></textarea>
          </div>
          <div className={styles.btnWrap}>
            <button className={styles.btn} type="submit">确认签到</button>
          </div>
        </form>
      </div>
    )
  }
} 